import { isInteger } from "lodash"
import moment from "moment"

export function getTimestamp(timestamp) {
  return moment(timestamp).format("YYYY, MMM, DD")
}

export function getTimestampFormated(timestamp) {
  return moment(timestamp).format("YYYY, MMM, DD, h:mm:ss a")
}

export function getDiffDateFromNow(date) {
  return moment(date).fromNow()
}

export function numberIsSame(first, second) {
  if (first === second) {
    return true
  } else {
    return false
  }
}

export function checkForMinusNumber(e) {
  if (Math.sign(e.target.value) === -1) {
    return true
  }
  return false
}

export const removeDocument = setselectedFiles => {
  setselectedFiles({})
}

export const getYears = () => {
  const year = new Date().getFullYear()
  const arr = []
  for (let i = 2020; i <= year; i++) {
    arr.push({ value: i, label: i })
  }
  return arr
}

export const getMonthName = month => {
  return moment()
    .month(month - 1)
    .format("MMMM")
}

export const setToFixed = number => {
  if (!isInteger(number)) {
    return typeof number
  } else {
    return number
  }
}

export const getExpirationDateBaseMonth = () => {
  // Include Moment.js if using npm
  // const moment = require('moment');

  // Current date and time
  let now = moment()

  // Add 6 months and format to 'YYYY-MM-DD'
  return now.add(6, "months").format("YYYY-MM-DD")
}

export const getExpirationDateBaseYear = () => {
  // Include Moment.js if using npm
  // const moment = require('moment');

  // Current date and time
  let now = moment()

  // Add 6 months and format to 'YYYY-MM-DD'
  let monthBased = now.add(6, "months").format("YYYY-MM-DD")

  // Parse the date again and add another 6 months, then format
  return moment(monthBased).add(6, "months").format("YYYY-MM-DD")
}

export const getSelectedValues = (value, allData) => {
  return value?.some(val => val.value === "all") ? allData : value
}

export const setAllLabelToSelectBoxOption = arr => {
  return [{ label: "All", value: "all" }, ...arr]
}

export const searchObjectValueManager = obj => {
  return (name, values) => {
    if (values) {
      obj[name] = values.map(ite => (ite.value ? ite.value : ite))
    }
  }
}
