import React, { useMemo, useRef, useState } from "react"
import { Button, Col, Form, Input, Label, Row } from "reactstrap"

import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import moment from "moment"
import { useSelector, useDispatch } from "react-redux"
import { isObject } from "lodash"
import Select from "react-select"
import { getProviderItems } from "store/provider-dashboard/provider-dashboard-action"
import { getSelectedValues, setAllLabelToSelectBoxOption } from "utils/utils"

const ProviderReceiptHealthReportFilter = ({
  onAdvancedSearchSubmit,
  loading,
}) => {
  const dateFlatpickerfrom = useRef(null)
  const dateFlatpickerto = useRef(null)
  const [category, setCategory] = useState(null)
  const [item, setItem] = useState(null)
  const [batch_number, setBatchNumber] = useState("")

  const dispatch = useDispatch()

  const [date, setDate] = useState({
    from: null,
    to: null,
  })

  const categories = useSelector(
    state => state.providerDashboard.ProviderItemCategoires
  )

  const items = useSelector(state => state.providerDashboard.ProviderItems)

  const warehouses = useSelector(
    state => state.providerDashboard.providerWarehouses
  )

  const filteredItems = useMemo(
    () =>
      items?.data.map(item => ({
        value: item.id,
        label: `${item.unfp_item_name} ( ${item.item_name} )`,
      })),
    [items]
  )

  const handleSubmit = () => {
    let data = {}

    data.date = {
      from: date.from ? date.from : null,
      to: date.to ? date.to : null,
    }
    if (item) {
      data.item = item.map(ite => (ite.value ? ite.value : ite))
    }
    data.category = isObject(category) ? category.value : null
    data.batchNumber = batch_number
    onAdvancedSearchSubmit(data)
  }

  const onClearHandler = () => {
    setItem(null)
    setCategory(null)
    setBatchNumber("")
    setDate({ from: null, to: null })
    onAdvancedSearchSubmit({})
  }

  const onCategoryChangeHandler = cate => {
    const selectedValue = getSelectedValues(cate, categories?.data)
    const ids = selectedValue?.map(val => val.value)
    setCategory(selectedValue)
    dispatch(getProviderItems({ ids: ids }))
  }

  const onItemChangeHandler = item => {
    setItem(getSelectedValues(item, filteredItems))
  }

  const onBatchNumberChange = batch_number => {
    setBatchNumber(batch_number.target.value)
  }

  return (
    <Form
      onSubmit={e => {
        e.preventDefault()
        handleSubmit()
        return false
      }}
    >
      <div className="mb-3">
        <Row>
          <Col>
            <Label className="form-label">Item Categories</Label>
            {categories?.data && (
              <Select
                isMulti={true}
                options={setAllLabelToSelectBoxOption(categories?.data)}
                value={category}
                onChange={onCategoryChangeHandler}
              />
            )}
          </Col>
          <Col>
            <Label className="form-label">Items</Label>
            {filteredItems && (
              <Select
                isMulti={true}
                options={setAllLabelToSelectBoxOption(filteredItems)}
                value={item}
                onChange={onItemChangeHandler}
              />
            )}
          </Col>
        </Row>
      </div>

      <div className="mb-3">
        <Row>
          <Col>
            <Label className="form-label">Batch Number</Label>
            <Input
              name="batch_number"
              placeholder="Batch Number"
              type="text"
              onChange={onBatchNumberChange}
              value={batch_number}
            />
          </Col>
        </Row>
      </div>

      {/* <div className="mb-3 ">
        <Label className="form-label">Date</Label>
        <Row>
          <Col>
            <Flatpickr
              ref={dateFlatpickerfrom}
              className="form-control d-block"
              placeholder="dd M,yyyy"
              options={{
                altInput: true,
                altFormat: "Y-m-d",
                dateFormat: "Y-m-d",
              }}
              onChange={value => {
                setDate(prev => {
                  return {
                    ...prev,
                    from: moment(value[0]).format("YYYY-MM-DD"),
                  }
                })
              }}
            />
          </Col>
          <Col>
            <Flatpickr
              ref={dateFlatpickerto}
              className="form-control d-block"
              placeholder="dd M,yyyy"
              options={{
                altInput: true,
                altFormat: "Y-m-d",
                dateFormat: "Y-m-d",
              }}
              onChange={value => {
                setDate(prev => {
                  return { ...prev, to: moment(value[0]).format("YYYY-MM-DD") }
                })
              }}
            />
          </Col>
        </Row>
      </div> */}

      <div className="d-flex flex-wrap gap-2 mb-4">
        <Button
          type="submit"
          className="btn btn-sm btn-primary"
          color="primary"
          disabled={loading}
        >
          {loading ? "Please Wait..." : "Search"}
        </Button>
        <Button
          onClick={onClearHandler}
          className="btn btn-sm btn-danger"
          color="primary"
        >
          Clear
        </Button>
      </div>
    </Form>
  )
}

export default ProviderReceiptHealthReportFilter
