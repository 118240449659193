import React, { useEffect, useState } from "react"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { useDispatch, useSelector } from "react-redux"
import DeleteModal from "components/Common/DeleteModal"
import {
  deleteManyProviderIn,
  deleteProviderIn,
  getProviderIns,
  updateProvidedReceiptStatus,
  uploadFileForManyReceipts,
} from "store/provider-in/provider-in-action"
import ProviderInListComponent from "components/ProviderIn/list"
import SAlert from "components/Alert"
import {
  getProviderInvNumber,
  getProviderIps,
  getProviderItemCategories,
  getProviderProjects,
} from "store/provider-dashboard/provider-dashboard-action"
import useSearch from "hooks/useSearch"
import ProviderInDetailModal from "components/ProviderIn/details"
import { exportProviderReceiptReport } from "store/provider-reports/p-report-action"

const ProviderInList = () => {
  document.title = "Receipts"

  const dispatch = useDispatch()
  const { data, error, loading } = useSelector(state => state.providerIn)
  const permissions = useSelector(state => state.auth.permissions)
  const [deleteModal, setDeleteModal] = useState(false)
  const [proIn, setProIn] = useState(null)
  const [search, setSearch] = useState(null)
  const [pageNumber, setPageNumber] = useState(10)
  const [according, setAccording] = useState({ order: "desc", name: "id" })
  const [advnacedSearch, setAdvancedSearch] = useState({})
  const [detailModal, setDetailModal] = useState(false)

  const exportStatus = useSelector(
    state => state.providerReports.providerExportReceiptReport
  )

  const {
    data: providedStatusResult,
    loading: provideStatusLoading,
    error: ProvidedStatusError,
  } = useSelector(state => state.providerIn.ProvidedStatus)

  const handleDeleteFunc = data => {
    if (proIn) {
      dispatch(deleteProviderIn(proIn.id))
    }
    setDeleteModal(false)
  }

  const onAllDeleteClicked = ids => {
    if (ids.length > 0) {
      dispatch(deleteManyProviderIn(ids))
    } else {
      SAlert({ text: "no receipt selected", status: "info" })
    }
  }
  const onUploadFileForMultipleReceipts = (ids, file) => {
    if (ids.length > 0) {
      console.log(file)
      const form = new FormData()
      form.append("ids", JSON.stringify(ids))
      form.append("document", file)
      dispatch(uploadFileForManyReceipts(form))
      SAlert({ text: "Upload Scucessfully" })
    } else {
      SAlert({ text: "no receipt selected", status: "info" })
    }
  }
  const onClickDelete = data => {
    setProIn(data)
    setDeleteModal(true)
  }

  const onPageClick = link => {
    dispatch(
      getProviderIns(link.url, pageNumber, according, search, advnacedSearch)
    )
  }

  const onSearchHandler = value => {
    setSearch(value)
  }

  const onChangeRowNumber = value => {
    setPageNumber(value)
  }

  const onRejectReceipt = data => {
    dispatch(updateProvidedReceiptStatus(data?.id, "Reject"))
    console.log("Reject", data)
  }
  const onAcceiptReceipt = data => {
    dispatch(updateProvidedReceiptStatus(data?.id, "Accept"))
    console.log("Accept", data)
  }

  const onChangeOrdering = value => {
    if (value.order) {
      setAccording(prev => {
        return { ...prev, order: value.order }
      })
    }
    if (value.name) {
      setAccording(prev => {
        return { ...prev, name: value.name }
      })
    }
  }

  useSearch(
    getProviderIns(undefined, undefined, undefined, search, advnacedSearch),
    search
  )

  useEffect(() => {
    dispatch(
      getProviderIns(undefined, pageNumber, according, search, advnacedSearch)
    )
  }, [pageNumber, according])

  useEffect(() => {
    dispatch(getProviderItemCategories())
    dispatch(getProviderProjects())
    dispatch(getProviderIps())
    dispatch(getProviderInvNumber())
  }, [])

  const onAdvancedSearchSubmit = data => {
    setAdvancedSearch(data)
    dispatch(getProviderIns(undefined, undefined, undefined, search, data))
  }

  const onExportBtnClick = () => {
    dispatch(
      exportProviderReceiptReport(pageNumber, according, search, advnacedSearch)
    )
  }

  const onClickDetail = data => {
    setProIn(data)
    setDetailModal(true)
  }

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteFunc}
        onCloseClick={() => setDeleteModal(false)}
      />
      <ProviderInDetailModal
        data={proIn}
        open={detailModal}
        setOpen={setDetailModal}
      />
      <Breadcrumbs title={"Provider"} breadcrumbItem={"Receipts"} />
      <ProviderInListComponent
        data={data}
        error={error}
        loading={loading}
        onClickDelete={onClickDelete}
        handleDeleteFunc={handleDeleteFunc}
        onPageClick={onPageClick}
        onSearch={onSearchHandler}
        onChangeRowNumber={onChangeRowNumber}
        onChangeOrdering={onChangeOrdering}
        onAllDeleteClicked={onAllDeleteClicked}
        onAdvancedSearchSubmit={onAdvancedSearchSubmit}
        permissions={permissions}
        onClickDetail={onClickDetail}
        onExportBtnClick={onExportBtnClick}
        exportStatus={exportStatus}
        onUploadFileForMultipleReceipts={onUploadFileForMultipleReceipts}
        onRejectReceipt={onRejectReceipt}
        onAcceiptReceipt={onAcceiptReceipt}
      />
    </React.Fragment>
  )
}

export default ProviderInList
