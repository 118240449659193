import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  providerStock: { error: null, loading: false, data: [] },
  ProviderIps: { error: null, loading: false, data: [] },
  ProviderItemCategoires: { error: null, loading: false, data: [] },
  ProviderItems: { error: null, loading: false, data: [] },
  ProviderInItems: { data: [], error: null, loading: null },
  ProviderOutItems: { data: [], error: null, loading: null },
  providerIpsStock: { error: null, loading: false, data: [] },
  providerIpsStockExport: { error: null, loading: false },
  providerWarehouses: { error: null, loading: false, data: [] },
  providerProviders: { error: null, loading: false, data: [] },
  providerProvinces: { error: null, loading: false, data: [] },
  providerFacilities: { error: null, loading: false, data: [] },
  providerProjects: { error: null, loading: false, data: [] },
  providerToProviderPendingCount: 0,
  providerDISNumbers: { error: null, loading: false, data: [] },
  providerInvNumbers: { error: null, loading: false, data: [] },
}

const providerDashboardSlice = createSlice({
  name: "providerDashboard",
  initialState,
  reducers: {
    setProviderStock(state, action) {
      state.providerStock = {
        error: action.payload.error,
        loading: action.payload.loading,
        data: action.payload.data,
      }
    },
    setProviderIps(state, action) {
      state.ProviderIps = {
        error: action.payload.error,
        loading: action.payload.loading,
        data: action.payload.data,
      }
    },
    setProviderItemCategories(state, action) {
      state.ProviderItemCategoires = {
        error: action.payload.error,
        loading: action.payload.loading,
        data: action.payload.data,
      }
    },
    setProviderToProviderPendingCount(state, action) {
      state.providerToProviderPendingCount = action.payload
    },
    setProviderItems(state, action) {
      state.ProviderItems = {
        error: action.payload.error,
        loading: action.payload.loading,
        data: action.payload.data,
      }
    },
    setProviderInItems(state, action) {
      state.ProviderInItems = {
        error: action.payload.error,
        loading: action.payload.loading,
        data: action.payload.data,
      }
    },
    setProviderOutItems(state, action) {
      state.ProviderOutItems = {
        error: action.payload.error,
        loading: action.payload.loading,
        data: action.payload.data,
      }
    },
    setProviderIpsStock(state, action) {
      state.providerIpsStock = {
        error: action.payload.error,
        loading: action.payload.loading,
        data: action.payload.data,
      }
    },
    setProviderIpsStockExport(state, action) {
      state.providerIpsStockExport = {
        error: action.payload.error,
        loading: action.payload.loading,
      }
    },
    setProviderWarehouses(state, action) {
      state.providerWarehouses = {
        error: action.payload.error,
        loading: action.payload.loading,
        data: action.payload.data,
      }
    },
    setProviderProviders(state, action) {
      state.providerProviders = {
        error: action.payload.error,
        loading: action.payload.loading,
        data: action.payload.data,
      }
    },
    setProviderProvinces(state, action) {
      state.providerProvinces = {
        error: action.payload.error,
        loading: action.payload.loading,
        data: action.payload.data,
      }
    },
    setProviderFacilities(state, action) {
      state.providerFacilities = {
        error: action.payload.error,
        loading: action.payload.loading,
        data: action.payload.data,
      }
    },
    setProviderProjects(state, action) {
      state.providerProjects = {
        error: action.payload.error,
        loading: action.payload.loading,
        data: action.payload.data,
      }
    },
    setProviderDISNumbers(state, action) {
      state.providerDISNumbers = {
        error: action.payload.error,
        loading: action.payload.loading,
        data: action.payload.data,
      }
    },
    setProviderInvNumbers(state, action) {
      state.providerInvNumbers = {
        error: action.payload.error,
        loading: action.payload.loading,
        data: action.payload.data,
      }
    },
    resetProviderStock(state) {
      state.providerStock = { error: null, loading: false, data: [] }
    },
    resetProviderIps(state) {
      state.ProviderIps = { error: null, loading: false, data: [] }
    },
    resetProviderItemCategories(state) {
      state.ProviderItemCategoires = { error: null, loading: false, data: [] }
    },
    resetProviderItems(state) {
      state.ProviderItems = { error: null, loading: false, data: [] }
    },
    resetProviderInItems(state) {
      state.ProviderInItems = { data: [], error: null, loading: null }
    },
    resetProviderOutItems(state) {
      state.ProviderOutItems = { data: [], error: null, loading: null }
    },
    resetProviderIpsStock(state) {
      state.providerIpsStock = { data: [], error: null, loading: null }
    },
    resetProviderWarehouses(state) {
      state.providerWarehouses = { data: [], error: null, loading: null }
    },
    resetProviderProviders(state) {
      state.providerProviders = { data: [], error: null, loading: null }
    },
    resetProviderProvinces(state) {
      state.providerProvinces = { data: [], error: null, loading: null }
    },
    resetProviderFacilities(state) {
      state.providerFacilities = { data: [], error: null, loading: null }
    },
    resetProviderProjects(state) {
      state.providerProjects = { data: [], error: null, loading: null }
    },
    resetProviderIPsStockExport(state) {
      state.providerIpsStockExport = { error: null, loading: false }
    },
    resetProviderDISNumbers(state) {
      state.providerDISNumbers = { error: null, loading: false }
    },
    resetProviderInvNumbers(state) {
      state.providerInvNumbers = { error: null, loading: false }
    },
  },
})

export const providerDashboardAction = providerDashboardSlice.actions

export default providerDashboardSlice
