import Chart1 from "components/Charts/Chart1"
import React, { useState } from "react"
import {
  Card,
  CardBody,
  Col,
  Row,
  CardHeader,
  Button,
  Collapse,
  Spinner,
  Table,
} from "reactstrap"
import ProviderStockFilter from "./ProviderStockFilter"
import { useSelector } from "react-redux"
import Chart2 from "components/Charts/Chart2"
import { isArray, isNaN } from "lodash"
import { Link } from "react-router-dom"
import moment from "moment"
import ProviderStockItemList from "./ProviderStockItemList"

const ProviderStock = ({
  onSubmit,
  onCategoryChange,
  provStock,
  onExport,
  onProviderSearchHandler,
  provData,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [searchDate, setSearchDate] = useState({
    fromDate: null,
    toDate: null,
    project: "all",
  })

  const [category, setCategory] = useState([])

  const exportStatus = useSelector(
    state => state.providerReports.providerInventoyExport
  )

  let data = []
  if (provStock) {
    data = provStock?.map(stock => {
      let receipt = !isNaN(
        parseInt(stock?.provider_ins_sum_first_in_qty_per_ip)
      )
        ? parseInt(stock?.provider_ins_sum_first_in_qty_per_ip)
        : 0

      let distribution = !isNaN(parseInt(stock?.provider_outs_sum_out_quantity))
        ? parseInt(stock?.provider_outs_sum_out_quantity)
        : 0

      let openingBalance = !isNaN(parseInt(stock?.openingBalance))
        ? parseInt(stock?.openingBalance)
        : 0
      let totalLostQuantity = !isNaN(parseInt(stock?.totalLostQuantity))
        ? parseInt(stock?.totalLostQuantity)
        : 0
      let beforDateDistribution = !isNaN(
        parseInt(stock?.beforeDateDistribution)
      )
        ? parseInt(stock?.beforeDateDistribution)
        : 0

      const items = stock.unfp_item_name
      const in_qty = receipt + (openingBalance - beforDateDistribution)

      const out_qty = distribution
      const balance =
        receipt + openingBalance - (distribution + beforDateDistribution)
      return {
        items,
        in_qty,
        out_qty,
        balance,
      }
    })
  }

  const provStockLoading = useSelector(
    state => state.providerDashboard.providerStock.loading
  )

  const getSearchData = values => {
    const cate = values?.Categories?.map(val => val?.label)
    setSearchDate({
      fromDate: values.fromDate ? values.fromDate : null,
      toDate: values.toDate ? values.toDate : null,
      project:
        values?.projects?.length > 0
          ? values?.projects?.map(val => val?.value)
          : [],
      projectName:
        values?.projects?.length > 0
          ? values?.projects?.map(val => val?.label)
          : [],
      category: cate,
    })

    setCategory(cate)
  }

  return (
    <CardBody>
      <Card>
        <CardHeader>
          <Row>
            <Col lg={6}>
              <Row>
                <Col
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <span style={{ fontSize: 20 }}>
                    AFGA Main Stock ( {category.join(" , ")} )
                    {searchDate.fromDate && (
                      <p
                        style={{
                          fontSize: 15,
                          color: "#19ada1",
                          fontWeight: 700,
                        }}
                      >
                        ( {searchDate.fromDate} ) - ( {searchDate.toDate} )
                      </p>
                    )}
                  </span>
                  &nbsp;
                  {provStockLoading && (
                    <Spinner
                      className="ms-1"
                      style={{ padding: 7, marginTop: 5 }}
                      size="sm"
                      color="primary"
                    />
                  )}
                </Col>
              </Row>
            </Col>
            <Col lg={6} style={{ textAlign: "right" }}>
              <Button
                onClick={onExport}
                className="btn btn-info"
                disabled={exportStatus?.loading}
              >
                {exportStatus?.loading ? "Please Wait..." : "Export"}
              </Button>
              &nbsp;
              <Button
                onClick={() => {
                  setIsOpen(!isOpen)
                }}
                className="btn btn-success "
              >
                {isOpen ? "Hide" : "Search"}
              </Button>
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <Collapse isOpen={isOpen}>
            <ProviderStockFilter
              onCategoryChange={onCategoryChange}
              onSubmit={onSubmit}
              provStockLoading={provStockLoading}
              getSearchData={getSearchData}
              onProviderSearchHandler={onProviderSearchHandler}
            />
          </Collapse>
          <Chart2 data={data} />
          <ProviderStockItemList
            provData={provData}
            data={provStock}
            searchDate={searchDate}
          />
        </CardBody>
      </Card>
    </CardBody>
  )
}

export default ProviderStock
