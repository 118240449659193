import React, { useMemo, useRef, useState } from "react"
import { Button, Col, Form, Label, Row } from "reactstrap"

import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import moment from "moment"
import { useSelector, useDispatch } from "react-redux"
import { isObject } from "lodash"
import Select from "react-select"
import {
  getProviderFacilities,
  getProviderFacilitiesByMultipleProvinces,
  getProviderItems,
  getProviderItemsByMultipleCategories,
} from "store/provider-dashboard/provider-dashboard-action"
import { providerDashboardAction } from "store/provider-dashboard/provider-dashboard-slice"
import { DISTRIBUTEDDETAIL, GEOREGION } from "constants/data"
import {
  getSelectedValues,
  searchObjectValueManager,
  setAllLabelToSelectBoxOption,
} from "utils/utils"

const ProviderDistributionReportFilter = ({
  onAdvancedSearchSubmit,
  loading,
}) => {
  const dateFlatpickerfrom = useRef(null)
  const dateFlatpickerto = useRef(null)
  const [category, setCategory] = useState(null)
  const [item, setItem] = useState(null)
  const [warehouse, setWarehouse] = useState(null)
  const [geoRegion, setGeoRegion] = useState(null)
  const [trType, setTrType] = useState(null)
  const [province, setProvince] = useState(null)
  const [facility, setFacility] = useState(null)
  const [ip, setIP] = useState(null)
  const [project, setProject] = useState(null)
  const [warehouse_disribute, setWarehouseDistribute] = useState(null)
  const [expireDate, setExpireDate] = useState({
    from: null,
    to: null,
  })

  const dispatch = useDispatch()

  const [date, setDate] = useState({
    from: null,
    to: null,
  })

  const categories = useSelector(
    state => state.providerDashboard.ProviderItemCategoires
  )

  const items = useSelector(state => state.providerDashboard.ProviderItems)

  const provinces = useSelector(
    state => state.providerDashboard.providerProvinces
  )

  const projects = useSelector(
    state => state.providerDashboard.providerProjects
  )

  const ips = useSelector(state => state.providerDashboard.ProviderIps)

  const facilities = useSelector(
    state => state.providerDashboard.providerFacilities
  )

  const warehouses = useSelector(
    state => state.providerDashboard.providerWarehouses
  )

  const filteredItems = useMemo(
    () =>
      items?.data.map(item => ({
        value: item.id,
        label: `${item.unfp_item_name} ( ${item.item_name} )`,
      })),
    [items]
  )

  const handleSubmit = () => {
    let data = {}
    const storeObjectValues = searchObjectValueManager(data)
    data.date = {
      from: date.from ? date.from : null,
      to: date.to ? date.to : null,
    }
    if (item) {
      data.item = item.map(ite => (ite.value ? ite.value : ite))
    }
    storeObjectValues("ip_ids", ip)
    storeObjectValues("categories", category)
    storeObjectValues("warehouses", warehouse)
    storeObjectValues("projects", project)
    storeObjectValues("geoRegion", geoRegion)
    storeObjectValues("trType", trType)
    storeObjectValues("provinces", province)
    data.facility = isObject(facility) ? facility.value : null
    data.warehouse_disribute = isObject(warehouse_disribute)
      ? warehouse_disribute.value
      : null

    onAdvancedSearchSubmit(data)
  }

  const onClearHandler = () => {
    dateFlatpickerfrom.current.flatpickr.clear()
    dateFlatpickerto.current.flatpickr.clear()
    setItem(null)
    setCategory(null)
    setGeoRegion(null)
    setTrType(null)
    setWarehouse(null)
    setDate({ from: null, to: null })
    setProvince(null)
    setFacility(null)
    setIP(null)
    setProject(null)
    onAdvancedSearchSubmit({})
    setWarehouseDistribute(null)
    dispatch(providerDashboardAction.resetProviderItems())
    dispatch(providerDashboardAction.resetProviderFacilities())

    onAdvancedSearchSubmit({})
  }

  const onCategoryChangeHandler = cate => {
    const selectedvalues = getSelectedValues(cate, categories?.data)
    const ids = selectedvalues?.map(val => val.value)
    setCategory(selectedvalues)
    dispatch(getProviderItemsByMultipleCategories(ids))
  }

  const onItemChangeHandler = item => {
    setItem(getSelectedValues(item, filteredItems))
  }

  const onWarehouseChange = warehouse => {
    setWarehouse(getSelectedValues(warehouse, warehouses?.data))
  }
  const onGeoRegionChangeHandler = region => {
    setGeoRegion(getSelectedValues(region, GEOREGION))
  }
  const onTransactionTypeChange = type => {
    setTrType(type)
  }

  const onProvinceChangeHandler = value => {
    const selectedvalues = getSelectedValues(value, provinces?.data)
    const ids = selectedvalues?.map(val => val.value)
    setProvince(selectedvalues)
    setFacility("")
    dispatch(getProviderFacilitiesByMultipleProvinces(ids))
  }

  const onFacilityChangeHandler = value => {
    setFacility(getSelectedValues(value, facilities?.data))
  }

  const onIPChangeHandler = value => {
    setIP(getSelectedValues(value, ips?.data))
  }

  const onProjectChangeHandler = value => {
    setProject(value)
  }

  const onWarehouseDistributeChangeHandler = value => {
    setWarehouseDistribute(getSelectedValues(value, DISTRIBUTEDDETAIL))
  }

  return (
    <Form
      onSubmit={e => {
        e.preventDefault()
        handleSubmit()
        return false
      }}
    >
      <div className="mb-3">
        <Row>
          <Col>
            <Label className="form-label">IPs</Label>
            {ips?.data && (
              <Select
                isMulti={true}
                options={setAllLabelToSelectBoxOption(ips?.data)}
                value={ip}
                onChange={onIPChangeHandler}
              />
            )}
          </Col>
          <Col>
            <Label className="form-label">Projects</Label>
            {projects?.data && (
              <Select
                options={projects?.data}
                value={project}
                onChange={onProjectChangeHandler}
              />
            )}
          </Col>
        </Row>
      </div>

      <div className="mb-3">
        <Row>
          <Col>
            <Label className="form-label">Province</Label>
            {provinces?.data && (
              <Select
                isMulti={true}
                options={setAllLabelToSelectBoxOption(provinces?.data)}
                value={province}
                onChange={onProvinceChangeHandler}
              />
            )}
          </Col>
          <Col>
            <Label className="form-label">Facilities</Label>
            {facilities?.data && (
              <Select
                isMulti={true}
                options={setAllLabelToSelectBoxOption(facilities?.data)}
                value={facility}
                onChange={onFacilityChangeHandler}
              />
            )}
          </Col>
        </Row>
      </div>

      <div className="mb-3">
        <Row>
          <Col>
            <Label className="form-label">Item Categories</Label>
            {categories?.data && (
              <Select
                isMulti={true}
                options={setAllLabelToSelectBoxOption(categories?.data)}
                value={category}
                onChange={onCategoryChangeHandler}
              />
            )}
          </Col>
          <Col>
            <Label className="form-label">Items</Label>
            {filteredItems && (
              <Select
                isMulti={true}
                options={setAllLabelToSelectBoxOption(filteredItems)}
                value={item}
                onChange={onItemChangeHandler}
              />
            )}
          </Col>
        </Row>
      </div>

      <div className="mb-3">
        <Row>
          <Col>
            <Label className="form-label">Warehouse</Label>
            {warehouses?.data && (
              <Select
                isMulti={true}
                options={setAllLabelToSelectBoxOption(warehouses?.data)}
                value={warehouse}
                onChange={onWarehouseChange}
              />
            )}
          </Col>
          <Col>
            <Label className="form-label">GEOGRAPHIC REGION</Label>
            {filteredItems && (
              <Select
                isMulti={true}
                options={setAllLabelToSelectBoxOption(GEOREGION)}
                value={geoRegion}
                onChange={onGeoRegionChangeHandler}
              />
            )}
          </Col>
        </Row>
      </div>

      <div className="mb-3">
        <Row>
          {/* <Col>
            <Label className="form-label">Transaction Type</Label>
            {filteredItems && (
              <Select
                options={[
                  { value: "Receipts", label: "Receipts" },
                  { value: "Distribution", label: "Distribution" },
                ]}
                value={trType}
                onChange={onTransactionTypeChange}
              />
            )}
          </Col> */}
          <Col>
            <Label className="form-label">Warehouse Distribute</Label>
            <Select
              isMulti={true}
              options={setAllLabelToSelectBoxOption(DISTRIBUTEDDETAIL)}
              value={warehouse_disribute}
              onChange={onWarehouseDistributeChangeHandler}
            />
          </Col>
        </Row>
      </div>

      <div className="mb-3 ">
        <Row>
          <Col>
            <Label className="form-label">Distribution From Date</Label>
            <Flatpickr
              ref={dateFlatpickerfrom}
              className="form-control d-block"
              placeholder="dd M,yyyy"
              options={{
                altInput: true,
                altFormat: "Y-m-d",
                dateFormat: "Y-m-d",
              }}
              onChange={value => {
                setDate(prev => {
                  return {
                    ...prev,
                    from: moment(value[0]).format("YYYY-MM-DD"),
                  }
                })
              }}
            />
          </Col>
          <Col>
            <Label className="form-label">Distribution To Date</Label>
            <Flatpickr
              ref={dateFlatpickerto}
              className="form-control d-block"
              placeholder="dd M,yyyy"
              options={{
                altInput: true,
                altFormat: "Y-m-d",
                dateFormat: "Y-m-d",
              }}
              onChange={value => {
                setDate(prev => {
                  return { ...prev, to: moment(value[0]).format("YYYY-MM-DD") }
                })
              }}
            />
          </Col>
        </Row>
      </div>

      <div className="d-flex flex-wrap gap-2 mb-4">
        <Button
          type="submit"
          className="btn btn-sm btn-primary"
          color="primary"
          disabled={loading}
        >
          {loading ? "Please Wait..." : "Search"}
        </Button>
        <Button
          onClick={onClearHandler}
          className="btn btn-sm btn-danger"
          color="primary"
        >
          Clear
        </Button>
      </div>
    </Form>
  )
}

export default ProviderDistributionReportFilter
