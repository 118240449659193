import SingleSelect from "components/Common/SelectBoxes/SingleSelect"
import React, { useMemo, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Button, Form, Input, Label } from "reactstrap"
import Select from "react-select"
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import moment from "moment"
import { getProviderItems } from "store/provider-dashboard/provider-dashboard-action"
import { providerDashboardAction } from "store/provider-dashboard/provider-dashboard-slice"
import {
  getSelectedValues,
  searchObjectValueManager,
  setAllLabelToSelectBoxOption,
} from "utils/utils"

const ProviderQuantificationFilter = ({ onAdvancedSearchSubmit, loading }) => {
  const providerIps = useSelector(state => state.providerDashboard.ProviderIps)
  const projects = useSelector(
    state => state.providerDashboard.providerProjects
  )
  const ProviderItems = useSelector(
    state => state.providerDashboard.ProviderItems
  )
  const ProviderItemCategoires = useSelector(
    state => state.providerDashboard.ProviderItemCategoires
  )

  const filteredItems = useMemo(
    () =>
      ProviderItems?.data.map(item => ({
        value: item.id,
        label: `${item.unfp_item_name} ( ${item.item_name} )`,
      })),
    [ProviderItems]
  )

  const dispatch = useDispatch()

  const fromdateFlatpicker = useRef(null)
  const todateFlatpicker = useRef(null)
  const [Ips, setIps] = useState(null)
  const [Categories, setCategories] = useState(null)
  const [item, setItem] = useState(null)
  const [batchNumber, setBatchNumber] = useState("")
  const [fromDate, setFromDate] = useState(null)
  const [toDate, setToDate] = useState(null)
  const [project, setProject] = useState(null)

  const handleSubmit = () => {
    let searchs = {}
    const storeObjectValues = searchObjectValueManager(searchs)
    storeObjectValues("ips", Ips)
    storeObjectValues("project", project)
    storeObjectValues("item", item)
    searchs.batchNumber = batchNumber
    searchs.from = fromDate
    searchs.to = toDate
    onAdvancedSearchSubmit(searchs)
  }

  const IpsChangeHandler = ip => {
    setIps(getSelectedValues(ip, providerIps?.data))
  }

  const onCategoryChangeHandler = category => {
    const selectedValue = getSelectedValues(
      category,
      ProviderItemCategoires?.data
    )
    const ids = selectedValue?.map(val => val.value)
    setCategories(selectedValue)
    dispatch(getProviderItems({ ids: ids }))
  }

  const onItemChangeHandler = item => {
    setItem(getSelectedValues(item, filteredItems))
  }
  const onProjectChangeHandler = proj => {
    setProject(getSelectedValues(proj, projects?.data))
  }

  const onClearHandler = () => {
    fromdateFlatpicker.current.flatpickr.clear()
    todateFlatpicker.current.flatpickr.clear()
    setIps(null)
    setCategories(null)
    setItem("")
    setProject(null)
    setBatchNumber("")
    setFromDate(null)
    setToDate(null)
    dispatch(providerDashboardAction.resetProviderItems())
    onAdvancedSearchSubmit({})
  }

  return (
    <Form
      onSubmit={e => {
        e.preventDefault()
        handleSubmit()
        return false
      }}
    >
      <div className="mb-3">
        <Label className="form-label">Projects</Label>
        {projects?.data && (
          <Select
            isMulti={true}
            options={setAllLabelToSelectBoxOption(projects?.data)}
            value={project}
            onChange={onProjectChangeHandler}
          />
        )}
      </div>

      <div className="mb-3">
        <Label className="form-label">Item Categories</Label>
        {ProviderItemCategoires?.data && (
          <Select
            isMulti={true}
            options={setAllLabelToSelectBoxOption(ProviderItemCategoires?.data)}
            value={Categories}
            onChange={onCategoryChangeHandler}
          />
        )}
      </div>

      {filteredItems && (
        <div className="mb-3">
          <Label className="form-label">Items</Label>
          <Select
            isMulti={true}
            options={setAllLabelToSelectBoxOption(filteredItems)}
            value={item}
            onChange={onItemChangeHandler}
          />
        </div>
      )}
      <div className="mb-3">
        <Label className="form-label">Batch Number</Label>
        <Input
          name="name"
          value={batchNumber}
          onChange={value => {
            setBatchNumber(value.target.value)
          }}
          placeholder="Name"
          type="text"
        />
      </div>

      <div className="mb-3 ">
        <Label className="form-label">From Date</Label>
        <Flatpickr
          ref={fromdateFlatpicker}
          className="form-control d-block"
          placeholder="dd M,yyyy"
          options={{
            altInput: true,
            altFormat: "Y-m-d",
            dateFormat: "Y-m-d",
          }}
          onChange={value => {
            setFromDate(moment(value[0]).format("YYYY-MM-DD"))
          }}
        />
        <br />
        <Label className="form-label">To Date</Label>
        <Flatpickr
          ref={todateFlatpicker}
          className="form-control d-block"
          placeholder="dd M,yyyy"
          options={{
            altInput: true,
            altFormat: "Y-m-d",
            dateFormat: "Y-m-d",
          }}
          onChange={value => {
            setToDate(moment(value[0]).format("YYYY-MM-DD"))
          }}
        />
      </div>

      <div className="d-flex flex-wrap gap-2">
        <Button
          type="submit"
          className="btn btn-sm btn-primary"
          color="primary"
          disabled={loading}
        >
          {loading ? "Please Wait..." : "Search"}
        </Button>
        <Button
          onClick={onClearHandler}
          className="btn btn-sm btn-danger"
          color="primary"
        >
          Clear
        </Button>
      </div>
    </Form>
  )
}

export default ProviderQuantificationFilter
